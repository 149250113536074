import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import { Typography } from '@material-ui/core';
import colors from '../../themes/main-colors';
import { Field, Content } from '../../contentful.d';

import PGEButton from '../pge-button/_PgeButton';
import { HeaderVariant } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiPaper-elevation1': {
        boxShadow: 'none ',
      },
      '& .MuiTypography-gutterBottom': {
        fontWeight: 'bold',
        color: colors.noirBlur,
        fontSize: '24pt',
        lineHeight: theme.typography.pxToRem(40),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(3),
          lineHeight: theme.typography.pxToRem(28),
        },
      },
      '& .MuiTypography-colorTextSecondary': {
        fontSize: theme.spacing(2.5),
        color: colors.noirBlur,
      },
      '& .MuiCardContent-root': {
        padding: theme.spacing(3.75, 6.25, 6.25, 6.25),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(3.75, 2.5, 3.75, 2.5),
        },
      },
      '& .MuiPaper-root': {
        backgroundColor: 'inherit',
      },
      '& .MuiCardActionArea-focusHighlight': {
        backgroundColor: 'inherit',
      },
      '& .MuiButtonBase-root': {
        cursor: 'auto',
      },
      '& .MuiContainer-root': {
        '& p.MuiTypography-root.pge-contentful-body': {
          margin: '20px 0',
        },
        '& li p.MuiTypography-root.pge-contentful-body': {
          margin: '0px',
        },
      },
    },
    card: {
      backgroundColor: ({ backgroundColor }: Props) =>
        getBackgroundColor(backgroundColor) + ' !important',
      borderRadius: 0,
    },
    svgMedia: {
      height: 70,
      width: 70,
      margin: theme.spacing(3.75, 6.25, 0, 6.25),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(3.75, 2.5, 0, 2.5),
      },
    },
    imgMedia: {
      height: 275,
      width: '100%',
      backgroundPosition: ({ imagePosition }: Props) => imagePosition,
    },
    textColor: {
      '& > a': {
        textDecoration: 'none',
        cursor: 'pointer !important',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    a: {
      underLine: 'none',
    },
  }),
);

const getBackgroundColor = (color: string | undefined): string => {
  let bgColor = colors.white;
  switch (color) {
    case 'None':
      bgColor = colors.white;
      break;
    case 'Light-Blue':
      bgColor = colors.riverBlue;
      break;
    case 'Grey':
      bgColor = colors.cloudGrey2;
      break;
    case 'Linen-White':
      bgColor = colors.linenWhite;
      break;
    case 'Yellow':
      bgColor = colors.lightYellow;
      break;
    default:
      bgColor = colors.white;
      break;
  }
  return bgColor;
};

interface Props {
  heading?: string;
  headingType?: string;
  shortDescription?: string;
  photo?: any;
  button?: IPGEButtonProps | null;
  link?: Content['Link'] | string;
  readonly imagePosition?: Field['Position'];
  backgroundColor?: string;
}

const CallOut = (props: Props) => {
  const classes = useStyles(props);

  const getHeading = (
    heading: any,
    buttonProps: IPGEButtonProps | null | undefined,
    link: Content['Link'] | string | undefined,
  ) => {
    if (
      buttonProps &&
      (buttonProps.buttonMode === null || buttonProps.buttonMode === '')
    ) {
      return (
        <PGEButton link={link} theme="Text" display="Inline">
          {props.heading}
        </PGEButton>
      );
    } else {
      return heading;
    }
  };

  const headerTag =
    !props?.headingType || !/^h[1-4]$/.test(props?.headingType)
      ? ('h2' as HeaderVariant)
      : (props?.headingType as HeaderVariant);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        {props.photo && props.photo !== 'null' && (
            <CardMedia className={!!!props.photo.includes('.svg') ? classes.imgMedia : classes.svgMedia} image={props.photo} />
        )}
        <CardContent>
          {props.heading && (
            <Typography variant={headerTag} className={classes.textColor}>
              {getHeading(props.heading, props?.button, props?.link)}
            </Typography>
          )}
          {props.shortDescription}
          {props.button?.buttonMode && (
            <PgeButton {...props.button} />
          )}
        </CardContent>
      </Card>
    </div>
  );
};
export default CallOut;
