import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';

const useStyles = makeStyles(() => ({
  titleText: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  spanText: {
    fontSize: '14px',
  },
  registerButton: {
    padding: '8px 42px',
    backgroundColor: colors.orange,
    borderColor: colors.orange,
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
    },
  },
}));

type Props = {
  navigateToRoute?: (route: string) => void;
};

const NavigationRegistration = ({ navigateToRoute = () => ({}) }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="body1" className={classes.titleText}>
          {t('NOT_REGISTERED_FOR_ONLINE_ACCESS')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          component="span"
          className={classes.spanText}
        >
          {t('CREATE_YOUR_ONLINE_ACCOUNT')}
        </Typography>
      </Grid>
      <Grid item>
        <PGEButton
          variant="contained"
          size="large"
          className={classes.registerButton}
          onClick={() => navigateToRoute(ROUTES.REGISTRATION)}
        >
          {t('REGISTER')}
        </PGEButton>
      </Grid>
    </Grid>
  );
};

export default NavigationRegistration;
